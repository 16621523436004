<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="messages-root">
    <page-header-for-lists
      header-text="Secure Messaging"
      :show-upgrade-banner="subscriptionT0 && !showingNoCount"
      :show-add-new-button="false"
      :show-mobile-background="true"
    ></page-header-for-lists>

    <messages-welcome v-if="showingNoCount && !welcomePagesViewed.messages" />

    <div
      v-if="!showingNoCount"
      class="flex align-justify align-middle search-wrapper"
      :class="isMobileWidth ? 'p-1' : 'py-1'"
    >
      <div class="flex align-middle gap-2" :class="{ 'w-100': isMobileWidth }">
        <SearchInput
          class="flex-1"
          placeholder="Search"
          input-wrapper-class="search-input-wrapper"
          input-class="search-input"
          button-class="btn tertiary search-input-button"
          icon-string="fa-light fa-magnifying-glass"
          icon-class="search-input-icon"
        />

        <div style="position: relative; width: fit-content">
          <button
            class="btn primary sort-filter-menu-button"
            style="position: relative"
            @click="openMenu"
            ref="sortFilterMenuButtonEl"
          >
            <FontAwesomeIcon
              icon="fa-light fa-bars-filter"
              class="filter-icon"
            />
          </button>

          <menu
            v-if="state.menuOpen"
            class="sort-filter-menu-wrapper"
            ref="sortFilterMenuEl"
          >
            <MessagesSortFilterMenu :result-count="0" />
          </menu>
        </div>

        <button
          v-if="!areFiltersDefault && !isMobileWidth"
          type="button"
          class="btn tertiary underline"
          @click="clearFilters"
        >
          {{ t('messages.sortAndFilter.clearFilters') }}
        </button>
      </div>

      <AddNewButton v-if="!showingNoCount && isDesktopWidth" />
    </div>

    <NoCountFoundMessageBox
      v-if="showingNoCount && welcomePagesViewed.messages"
      icon-string="fak fa-messages"
      text="No message activity"
    >
      <template #footer>
        <button type="button" class="btn primary" @click="openAddMessageForm">
          New message
        </button>
      </template>
    </NoCountFoundMessageBox>

    <layout-box
      v-else-if="!showingNoCount"
      :class="{ 'mobile-content': isMobileWidth }"
      style="height: 100%"
    >
      <span ref="scrollToRef"></span>

      <infinite-scroll-table
        v-if="!searchMode || (searchMode && searchedMessageThreads.length)"
        :table-data="tableData"
        :items="searchMode ? searchedMessageThreads : messageThreads"
        :count="searchMode ? searchedMessageThreads.length : count"
        :page="state.page"
        :include-adsense="true"
        :adsense-slot-code-mobile-key="adsenseMobileKey"
        :adsense-slot-code-desktop-key="adsenseDesktopKey"
        table-container-div-class="conversation-list"
        :load-more-items="searchMode ? loadMoreSearchResults : loadMoreMessages"
        @load-more="loadMore"
        @row-click="
          (item) => (searchMode ? searchRowClick(item) : rowClick(item))
        "
      >
        <!-- Message Item -->
        <template #item="{ item }">
          <div
            class="w-100"
            tabindex="0"
            @keydown.enter="handleKeyDown"
            @keydown.space="handleKeyDown"
          >
            <div v-if="!searchMode">
              <div class="w-100 flex row">
                <div
                  :class="{ 'new-message': item.isNew }"
                  class="size-000 conversation-content"
                  v-skeleton="{
                    loading: item.skeletonLoading ?? state.fetchingThreads,
                    minWidth: 15,
                    maxWidth: 25
                  }"
                >
                  <h3 class="ellipsis" :class="{ 'font-bold': item.isNew }">
                    {{ item.subject }}
                  </h3>
                </div>
                <div class="list-meta">
                  <time
                    class="message-date gray-light"
                    v-skeleton="{
                      loading: item.skeletonLoading ?? state.fetchingThreads,
                      minWidth: 5,
                      maxWidth: 5
                    }"
                  >
                    {{
                      isToday(item.lastReplyDate)
                        ? formatFromUtcTime(item.lastReplyDate)
                        : formatDate(item.lastReplyDate, false)
                    }}
                  </time>
                </div>
              </div>
              <div>
                <ul
                  v-if="item.isNew"
                  class="detail-items stack conversation-content mt-000"
                >
                  <li
                    class="detail-item font-size-0 flex align-middle"
                    style="gap: 0.25rem"
                  >
                    <span
                      class="gray-light message-preview"
                      v-skeleton="{
                        loading: item.skeletonLoading ?? state.fetchingThreads,
                        minWidth: 5,
                        maxWidth: 15
                      }"
                    >
                      New message from
                      {{ getUsersFirstNameFromUserId(item.lastReplyUserID) }}
                    </span>
                  </li>
                </ul>

                <ul
                  v-else
                  class="detail-items stack conversation-content mt-000"
                  :class="{ 'mr-2': isMobileWidth }"
                >
                  <li
                    class="detail-item font-size-0 flex align-middle"
                    style="gap: 0.25rem"
                  >
                    <span
                      class="gray-light message-preview"
                      v-skeleton="{
                        loading: item.skeletonLoading ?? state.fetchingThreads,
                        minWidth: 5,
                        maxWidth: 15
                      }"
                    >
                      <span>
                        {{ getUsersFirstNameFromUserId(item.lastReplyUserID) }}:
                      </span>
                      {{ item.lastReplyPreview }}
                      <!-- Last reply from {{ item.lastReplyName }} -->
                    </span>
                  </li>
                </ul>
              </div>
            </div>

            <MessageSearchResult
              v-else
              :item="item"
              :loading="state.fetchingThreads"
              :downloading="
                item.attachment?.itemID == state.downloadingAttachmentItemId
              "
              @download="downloadAttachment(item)"
            />
          </div>
        </template>
      </infinite-scroll-table>

      <div v-else class="no-results-found">
        <FontAwesomeIcon
          icon="fa-kit fa-messages"
          class="no-results-found-icon"
        />

        <span class="no-results-found-title">
          {{ t('messages.sortAndFilter.noResults.title') }}
        </span>

        <span class="no-results-found-subtitle">
          {{ t('messages.sortAndFilter.noResults.subtitle') }}
        </span>

        <button
          type="button"
          class="btn secondary no-results-found-button"
          @click="clearFilters"
        >
          {{ t('messages.sortAndFilter.noResults.button') }}
        </button>
      </div>
    </layout-box>

    <back-to-top v-if="!showingNoCount" />

    <div class="downloads">
      <DownloadList v-if="downloads.length > 0" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, watch, computed, ref, onBeforeMount, nextTick } from 'vue'
import PageHeaderForLists from '@/layouts/components/PageHeaderForListsComponent.vue'
import { storeToRefs } from 'pinia'
import { useCommonStore } from '@/stores/CommonStore'
import { useMessagesStore } from '@/stores/MessagesStore'
import { useAccountSettingsStore } from '@/stores/AccountSettingsStore'
import type { IMessagingThread } from '@/models/models'
import InfiniteScrollTable from '@/components/infiniteScrollTable.vue'
import NoCountFoundMessageBox from '@/components/NoCountFoundMessageBox.vue'
import { useRouter } from 'vue-router'
import type { IGlobalSearchResult } from '@/models/interfaces'
import { MessagingThreadItem } from '@/models/models'
import LayoutBox from '@/layouts/components/LayoutBoxComponent.vue'
import constants from '@/exports/constants'
import BackToTop from '@/components/library/BackToTopButton.vue'
import messagesWelcome from './components/messagesWelcome.vue'
import { useModals } from '@/composables/useModal/useModal'
import { useI18n } from 'vue-i18n'
import { onClickOutside, useWindowScroll } from '@vueuse/core'
import SearchInput from '@/components/library/SearchInput.vue'
import { SortOption } from '@/models/enums'
import MessagesSortFilterMenu from './components/MessagesSortFilterMenu.vue'
import MessageSearchResult from './components/MessagesSearchResult.vue'
import { useAttachmentsStore } from '@/stores/AttachmentsStore'
import DownloadList from '@/components/DownloadList.vue'
import AddNewButton from '@/components/library/AddNewButton.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import MessagesSortFilterMenuModal from './components/MessagesSortFilterMenuModal.vue'
import MessagesWelcome from './components/messagesWelcome.vue'
const { t } = useI18n({ useScope: 'global' })

const router = useRouter()

const { closeModal, generateModal, createSlot, HTMLtoComponent } = useModals()

const commonStore = useCommonStore()
const {
  layoutCommonDataLoaded,
  isMobileWidth,
  showingNoCount,
  showInfoModal,
  welcomePagesViewed,
  fullUserInfo,
  searchMode,
  searchTerm,
  downloads,
  isDesktopWidth
} = storeToRefs(commonStore)
const {
  setSetObserverValMethod,
  setPageHeaders,
  formatDate,
  formatFromUtcTime,
  isToday,
  isBannerCloseCooldownEnded,
  setShowingNoCount,
  setLayoutOptions,
  getUsersFirstNameFromUserId,
  setSearchMode,
  startDownload,
  setshowInfoModal
} = commonStore

const messagesStore = useMessagesStore()
const {
  count,
  messageThreads,
  newThreadAlert,
  newReplyAlert,
  loadMoreMessages,
  loadMoreSearchResults,
  searchedMessageThreads,
  threadListPage,
  threadListScrollPosition,
  sortFilterSelectedOptions,
  areFiltersDefault
} = storeToRefs(messagesStore)
const {
  setSelectedMessageThread,
  fetchThreadsPaged,
  fetchSearchThreadsPaged,
  setSelectedConversationSearchResult,
  setThreadListSearchTerm,
  setThreadListPage,
  setThreadListScrollPosition,
  resetThreads,
  clearFilters,
  setSortBy,
  setSelectedMatchedTokens
} = messagesStore

const accountSettingsStore = useAccountSettingsStore()
const { subscriptionT0, subscriptionT1, subscriptionT2 } =
  storeToRefs(accountSettingsStore)

const attachmentsStore = useAttachmentsStore()
const { fetchAttachmentSasUrl } = attachmentsStore

interface IMessagesState {
  page: number
  fetchingThreads: boolean
  menuOpen: boolean
  fetchingMoreThreads: boolean
  downloadingAttachmentItemId: number
}

const state = reactive<IMessagesState>({
  page: 1,
  fetchingThreads: true,
  menuOpen: false,
  fetchingMoreThreads: false,
  downloadingAttachmentItemId: -1
})

const tableData = {
  headerItems: [
    {
      displayName: 'CREATED BY',
      propertyName: 'CreatedBy',
      headerClass: 'col-sm-12 col-md-3',
      itemClass: 'col-sm-12 col-md-3 name'
    },
    {
      displayName: 'SUBJECT',
      propertyName: 'Subject',
      headerClass: 'col-sm-12 col-md-4',
      itemClass: 'col-sm-12 col-md-4 subject'
    },
    {
      displayName: 'LAST REPLY',
      propertyName: 'LastReplyDate',
      headerClass: 'col-sm-12 col-md-5',
      itemClass: 'col-sm-12 col-md-5 date'
    }
  ]
}

const adsenseDesktopKey = import.meta.env
  .VITE_APP_ADSENSE_SLOTCODE_MESSAGES_DESKTOP
const adsenseMobileKey = import.meta.env
  .VITE_APP_ADSENSE_SLOTCODE_MESSAGES_MOBILE

const scrollToRef = ref<HTMLElement>()
const sortFilterMenuButtonEl = ref<HTMLElement | null>(null)
const sortFilterMenuEl = ref<HTMLElement | null>(null)

const hoverBackground = computed(() => (searchMode ? '#f9f9ff' : ''))

const { y } = useWindowScroll()

// const fooRef = ref<HTMLElement>()

// const showWebLayerChildRef = computed(() => {
//   console.log('showWebLayerChildRef.value: ', showWebLayerParentDivRef.value?.firstChild as HTMLElement)
//   return (showWebLayerParentDivRef.value?.firstChild as HTMLElement)
// })

// const showWebLayer = computed(() => {
//   return showWebLayerChildRef.value.dataset['data-showweblayer'] == 'true'
// })

// watch(
//   () => showWebLayerParentDivRef.value?.firstChild,
//   async (val) => {
//     console.log('placeholder parent div child changed')
//     if ((val as HTMLElement).dataset['data-showweblayer'] == 'true')
//       (showWebLayerParentDivRef.value?.firstChild as HTMLElement).click()
//   }
// )

onBeforeMount(async () => {
  if (layoutCommonDataLoaded.value) {
    init()
  }
  setPageHeadersLocal()
})

onClickOutside(sortFilterMenuEl, () => (state.menuOpen = false), {
  ignore: [sortFilterMenuButtonEl]
})

// onMounted(() => {
//   if (layoutCommonDataLoaded.value) {
//     init()
//   }
// })

// onUpdated(async () => {
//   await nextTick(() => {
//     if (state.fetchingThreads) state.fetchingThreads = false
//   })
// })

watch(layoutCommonDataLoaded, async (val) => {
  if (val) {
    init()
  }
})

watch(
  () => fullUserInfo.value,
  async (ci, ciOldVal) => {
    const caseId = ci.caseId || 0
    if (ci != ciOldVal && caseId > 0) {
      setPageHeadersLocal()
    }
  }
)

watch(
  () => [subscriptionT0.value, subscriptionT1.value, subscriptionT2.value],
  async () => {
    setSetObserverValMethod(true)
  }
)

watch(
  () => newReplyAlert.value,
  async (val) => {
    if (val.newReply) {
      //should only ever be one anyway
      const newThread = messageThreads.value.find(
        (t) => t.threadID == val.threadId
      )
      if (newThread) {
        //newThread.isNew = true
        const prevScrollY = window.scrollY
        const prevScrollX = window.scrollX

        await getConversations()
        scrollTo({ top: prevScrollY, left: prevScrollX, behavior: 'instant' })
      }
    }
  }
)

watch(
  () => newThreadAlert.value,
  async (val) => {
    if (val.newThread) {
      await getConversations()
    }
  }
)

watch(
  () => y.value,
  async (val) => setThreadListScrollPosition(val)
)

async function init() {
  const scrollPosition = threadListScrollPosition.value

  state.fetchingThreads = true

  for (state.page; state.page <= threadListPage.value; ++state.page) {
    await getConversations()
  }
  // reset the layoutoptions so we know if there are messages
  setLayoutOptionsLocal()

  if (subscriptionT0) {
    setSetObserverValMethod(true)
  }
  state.page = threadListPage.value

  state.fetchingThreads = false

  nextTick(() => {
    if (scrollPosition) {
      window.scrollTo({ top: scrollPosition, behavior: 'instant' })
    }
  })
}

watch([searchTerm, sortFilterSelectedOptions], async ([term], [oldTerm]) => {
  resetThreads()

  if (!term.length && areFiltersDefault.value) {
    setSearchMode(false)
  } else setSearchMode(true)

  if (
    !term.length &&
    sortFilterSelectedOptions.value.sortBy == SortOption.Relevance
  ) {
    setSortBy(SortOption.DateDescending)
  }

  if (term.length && !oldTerm.length && !areFiltersDefault.value) {
    clearFilters()
  }

  if (!term.length && oldTerm.length && !areFiltersDefault.value) {
    clearFilters()
  }

  // const remSize = parseFloat(
  //   getComputedStyle(document.documentElement).fontSize
  // )
  // let scrollByValue

  // if (isMobileWidth.value) scrollByValue = (2.875 * remSize + 48 + remSize) * -1
  // else if (isTabletWidth.value) scrollByValue = (3.125 * remSize + remSize) * -1
  // else scrollByValue = (4.5 * remSize + remSize) * -1

  // scrollToRef.value?.scrollIntoView({ behavior: 'instant' })
  // window.scrollBy({ top: scrollByValue, behavior: 'instant' })

  setThreadListPage((state.page = 1))

  setThreadListSearchTerm(term ?? '')

  state.fetchingThreads = true
  await getConversations()
  state.fetchingThreads = false
})

async function getConversations() {
  if (searchMode.value) {
    await fetchSearchThreadsPaged({
      ...sortFilterSelectedOptions.value,
      ...{ page: state.page, searchTerm: searchTerm.value }
    })
    setShowingNoCount(false)
  } else {
    await fetchThreadsPaged({
      page: state.page
    })

    setShowingNoCount(count.value == 0)
  }
}

async function loadMore() {
  if (
    (!loadMoreMessages.value || searchMode.value) &&
    (!loadMoreSearchResults.value || !searchMode.value)
  ) {
    return
  }

  if (
    (loadMoreMessages.value && !searchMode.value) ||
    (loadMoreSearchResults.value && searchMode.value)
  ) {
    state.fetchingMoreThreads = true
    setThreadListPage(++state.page)
    await getConversations()
    state.fetchingMoreThreads = false
  }
}

function openAddMessageForm() {
  router.push({
    name: 'messagesEntry'
  })
}

function rowClick(item: IMessagingThread) {
  const _item = {
    ...item,
    threadItems: [
      new MessagingThreadItem({
        itemType: 1,
        userID: fullUserInfo.value.userId
      }),
      new MessagingThreadItem({
        itemType: 2,
        userID: 0
      }),
      new MessagingThreadItem({
        itemType: 1,
        userID: 0
      }),
      new MessagingThreadItem({
        itemType: 2,
        userID: fullUserInfo.value.userId
      }),
      new MessagingThreadItem({
        itemType: 1,
        userID: fullUserInfo.value.userId
      }),
      new MessagingThreadItem({
        itemType: 2,
        userID: 0
      })
    ]
  }

  setSelectedMatchedTokens([])
  setSelectedMessageThread(_item)

  router.push({
    name: 'viewMessage',
    params: {
      itemId: item.threadID
    }
  })
}

function searchRowClick(item: IGlobalSearchResult) {
  //view message fetches the selected thread anyway, only requiring the ID
  const thread: IMessagingThread = {
    threadID: item.conversation?.itemID,
    subject: item.conversation?.title ?? '',
    threadItems: [
      new MessagingThreadItem({
        itemType: 1,
        userID: fullUserInfo.value.userId
      }),
      new MessagingThreadItem({
        itemType: 2,
        userID: 0
      }),
      new MessagingThreadItem({
        itemType: 1,
        userID: 0
      }),
      new MessagingThreadItem({
        itemType: 2,
        userID: fullUserInfo.value.userId
      }),
      new MessagingThreadItem({
        itemType: 1,
        userID: fullUserInfo.value.userId
      }),
      new MessagingThreadItem({
        itemType: 2,
        userID: 0
      })
    ]
  }

  setSelectedConversationSearchResult(item)
  setSelectedMatchedTokens(item.matchedTokens ?? [])

  if (item.conversation?.isNew) {
    const el = generateModal({
      default: {
        headerText: t('messages.sortAndFilter.unreadConversationModal.header'),
        footerButtonLabel: t(
          'messages.sortAndFilter.unreadConversationModal.continueButton'
        )
      },
      slot: createSlot(
        'content',
        HTMLtoComponent(
          t('messages.sortAndFilter.unreadConversationModal.content')
        ),
        { class: 'p-3' }
      ),
      config: {
        showFooter: true,
        addContentPadding: false,
        showSecondaryCTA: true,
        secondaryCTALabel: t(
          'messages.sortAndFilter.unreadConversationModal.cancelButton'
        ),
        footerStyle: 'flex-reverse gap-4'
      },
      callback: {
        confirmFn: () => {
          goToConversation(thread)
          closeModal(el)
        },
        closeFn: () => false,
        secondaryFn: () => closeModal(el)
      }
    })

    return
  }

  goToConversation(thread)
}

function goToConversation(thread: IMessagingThread) {
  setSelectedMessageThread(thread)

  router.push({
    name: 'viewMessage',
    params: {
      itemId: thread.threadID
    }
  })
}

const handleKeyDown = (e: KeyboardEvent) => {
  const key = e.target as HTMLElement
  e.preventDefault()
  key.click()
}

function setPageHeadersLocal() {
  setPageHeaders({
    page: 'Messages',
    pageName: fullUserInfo.value.coparentFullName
      ? `${t('titles.messaging')} ${fullUserInfo.value.coparentFullName}`
      : t('titles.messaging'),
    pageNameMobile: fullUserInfo.value.coparentFullName
      ? `${fullUserInfo.value.coparentFullName}`
      : t('titles.messagingMobile'),
    pageIcon: 'fak fa-messages',
    searchEnabled: true,
    pageData: {},
    searching: true,
    welcomePageName: 'messagesWelcome'
  })
  setLayoutOptions({
    pageType: constants.pageType.list,
    headerText: t('titles.messagingMobile'),
    subHeaderText: '',
    showBackbutton: false,

    showUpgradeBanner: false,
    showAdsNotInList: false,
    showAddNewButton: false,
    showInfoButton: false
  })
}

function setLayoutOptionsLocal() {
  setLayoutOptions({
    pageType: constants.pageType.list,
    headerText: t('titles.messagingMobile'),
    subHeaderText: '',
    showBackbutton: false,
    showUpgradeBanner:
      subscriptionT0.value && isBannerCloseCooldownEnded('messages'),
    showAdsNotInList: false,
    showAddNewButton:
      !showingNoCount.value || welcomePagesViewed.value.messages,
    showInfoButton: welcomePagesViewed.value.messages || !showingNoCount.value
  })
}

// function buildSearchConversationsPagedRequest(
//   options: IMessagesSortFilterSelectedOptions
// ) {
//   const request = {} as ISearchConversationsPagedRequest

//   switch (options.sort) {
//     case MessagesSortFilterSortOptions.Relevance:
//       request.sortBy = 0
//       break
//     case MessagesSortFilterSortOptions.NewestToOldest:
//       request.sortBy = 1
//       break
//     case MessagesSortFilterSortOptions.OldestToNewest:
//       request.sortBy = 2
//   }

//   if (options.filter?.[MessagesSortFilterFilterOptions.Subject]) {
//     request.dataTypes ? request.dataTypes.push(0) : (request.dataTypes = [0])
//   }

//   if (options.filter?.[MessagesSortFilterFilterOptions.Message]) {
//     request.dataTypes ? request.dataTypes.push(1) : (request.dataTypes = [1])
//   }

//   if (options.filter?.[MessagesSortFilterFilterOptions.Attachment]) {
//     request.dataTypes ? request.dataTypes.push(2) : (request.dataTypes = [2])
//   }

//   if (options.filter?.[MessagesSortFilterFilterOptions.UnreadOnly]) {
//     request.conversationFilter = { unread: true }
//   }

//   switch (options.sent) {
//     case MessagesSortFilterSentOptions.Both:
//       request.ownerFilter = 0
//       break
//     case MessagesSortFilterSentOptions.You:
//       request.ownerFilter = 1
//       break
//     case MessagesSortFilterSentOptions.Coparent:
//       request.ownerFilter = 2
//       break
//   }

//   switch (options.date) {
//     case MessagesSortFilterDateOptions.Today:
//       request.dateFilter = {
//         startDate: moment.utc().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
//         endDate: moment.utc().endOf('day').format('YYYY-MM-DDTHH:mm:ss')
//       }
//       break
//     case MessagesSortFilterDateOptions.ThisWeek:
//       request.dateFilter = {
//         startDate: moment.utc().startOf('week').format('YYYY-MM-DDTHH:mm:ss'),
//         endDate: moment.utc().endOf('week').format('YYYY-MM-DDTHH:mm:ss')
//       }
//       break
//     case MessagesSortFilterDateOptions.ThisYear:
//       request.dateFilter = {
//         startDate: moment.utc().startOf('year').format('YYYY-MM-DDTHH:mm:ss'),
//         endDate: moment.utc().endOf('year').format('YYYY-MM-DDTHH:mm:ss')
//       }
//       break
//     case MessagesSortFilterDateOptions.LastYear:
//       request.dateFilter = {
//         startDate: moment
//           .utc()
//           .year(+moment.utc().format('YYYY') - 1)
//           .startOf('year')
//           .format('YYYY-MM-DDTHH:mm:ss'),
//         endDate: moment
//           .utc()
//           .year(+moment.utc().format('YYYY') - 1)
//           .endOf('year')
//           .format('YYYY-MM-DDTHH:mm:ss')
//       }
//       break
//     case MessagesSortFilterDateOptions.Custom:
//       if (options.rangeFrom && options.rangeTo) {
//         request.dateFilter = {
//           startDate: options.rangeFrom?.format('YYYY-MM-DDTHH:mm:ss'),
//           endDate: options.rangeTo?.format('YYYY-MM-DDTHH:mm:ss')
//         }
//       }
//       break
//   }

//   return request
// }

async function downloadAttachment(item: IGlobalSearchResult) {
  if (!item.attachment?.fileName || !item.attachment?.itemID) {
    return
  }

  state.downloadingAttachmentItemId = item.attachment.itemID
  const url = await fetchAttachmentSasUrl(item.attachment.itemID)
  state.downloadingAttachmentItemId = -1

  //removes <em> tags from the file name when searching
  //should work fine - you can't put angle brackets in file names
  const cleanFileName = new DOMParser().parseFromString(
    item.attachment.fileName,
    'text/html'
  ).documentElement.textContent

  if (url) {
    startDownload(
      url,
      cleanFileName ?? item.attachment.fileName,
      item.attachment.mimeType
    )
  }
}

function openMenu() {
  if (isMobileWidth.value) {
    const el = generateModal({
      slot: createSlot('content', MessagesSortFilterMenuModal),
      config: {
        showFooter: false,
        showHeader: false,
        addContentPadding: false
      },
      callback: {
        confirmFn: () => closeModal(el)
      }
    })

    return
  }

  state.menuOpen = !state.menuOpen
}

watch(showInfoModal, (showModal) => {
  if (showModal) {
    generateModal({
      slot: {
        content: createSlot('content', MessagesWelcome, {
          isModal: true
        }).content
      },
      config: {
        showHeader: false,
        showFooter: false,
        showCloseButton: true,
        addContentPadding: true
      },
      callback: {
        closeFn: () => setshowInfoModal(false)
      }
    })
  }
})
</script>
<style lang="scss" scoped>
.conversation-content {
  min-width: 0;
}
.conversation-list #tableBody .row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-right: var(--size-1);
  ul.detail-items {
    padding-right: var(--size-1);

    .detail-item {
      padding-right: var(--size-2);

      @media (width >= 48em) {
        padding-right: var(--size-5);
      }
    }
  }
}

:deep(#tableBody li.row) {
  border-bottom: solid 1px #b7b7c4 !important;
  padding: var(--size-1) 0;
}
.detail-item {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-item-search {
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Red dot notification */
.new-message {
  position: relative;
}
.new-message::before {
  content: '';
  width: var(--size-00);
  height: var(--size-00);
  border-radius: 50%;
  display: block;
  background-color: #cf375d;
  position: absolute;
  inset-inline-start: -0.8625rem;
  inset-block-start: 0.25rem;

  @media (width >= 48em) {
    inset-inline-start: -1rem;
  }
}
.list-meta {
  min-width: 0;
  flex: none;
}

.message-preview {
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-00);

  @media (width >= 48em) {
    font-size: var(--font-size-0);
  }
}

.message-date {
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: var(--font-size-00);

  @media (width >= 48em) {
    font-size: var(--font-size-0);
  }
}

.layout-box-container {
  @media (width >= 48em) {
    border-radius: var(--radius-md);
    padding: var(--size-7) var(--size-7) var(--size-1) var(--size-7);
    box-shadow: var(--box-shadow-1);
  }

  :deep(.conversation-list) {
    position: relative;
    left: 1rem;

    @media (width >= 48em) {
      position: static;
    }
  }
}

.reply-date {
  font-size: var(--font-size-000);
  @media (width >= 48em) {
    font-size: var(--font-size-00);
  }
}

.hover-grey:hover {
  background-color: v-bind(hoverBackground);
}

.ml1 {
  margin-left: 1rem;
}

.mobile-content {
  margin-block-start: 0;
}

.font-bold {
  font-variation-settings: 'wght' 700;
}

.sort-filter-menu-button {
  background: light-dark(var(--gray-3), var(--gray-4));
  min-width: 0;
  transition: background 0.25s ease-in-out;

  &:hover {
    background: light-dark(var(--gray-5), var(--gray-6));
  }

  & > .filter-icon {
    width: 1.125rem;
    height: 1.125rem;
    color: var(--gray-12);
  }

  @media (width >= 48em) {
    background: light-dark(var(--gray-3), var(--gray-4));

    &:hover {
      background: light-dark(var(--gray-4), var(--gray-6));
    }
  }
}

.sort-filter-menu-wrapper {
  background: var(--surface-1);
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  z-index: 1;
  border-radius: var(--radius-lg);
  box-shadow: var(--box-shadow-3);
  border: solid 1px var(--surface-3);
  padding-block: var(--size-0);
  width: 11.5625rem;
}

.downloads {
  max-width: 100vw;
  width: fit-content;
  height: fit-content;

  position: fixed;
  right: 0;
  bottom: var(--size-1);
  z-index: 1999;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1.25rem;

  margin-inline-start: auto;
  padding-inline: var(--size-1);
}

:deep(.search-input-wrapper) {
  position: relative;
}

:deep(.search-input-button) {
  position: absolute;
  left: var(--size-00);
  width: 2.5rem;
  height: 2.5rem;
  padding: var(--size-00);
  transform: translateY(2px);
}

:deep(.search-input-icon) {
  width: 0.875rem !important;
  height: 0.875rem !important;
  color: var(--text-1);
}

:deep(.search-input) {
  border: solid 1px var(--surface-3);
  border-radius: 5rem !important;
  padding-block: var(--size-0);
  padding-inline: var(--size-4) var(--size-0);

  font-size: var(--font-size-1);
  line-height: 1;
  color: var(--text-2);
}

.search-wrapper {
  background: var(--surface-1);

  @media (width >= 48em) {
    background: unset;
  }
}

.messages-root {
  display: flex;
  flex-direction: column;
  gap: 0;
  height: 100%;

  background: var(--surface-1);

  @media (width >= 48em) {
    background: unset;
  }
}

.no-results-found {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &-icon {
    width: 3.5rem !important;
    height: 3.5rem !important;

    color: var(--text-2);
  }

  &-title {
    font-variation-settings: 'wght' 500;
    font-size: var(--font-size-2);

    margin-block-start: var(--size-1);
  }

  &-subtitle {
    font-size: var(--font-size-0);
  }

  &-button {
    margin-block-start: var(--size-2);
  }
}
</style>
